import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setQuizQuestionComplete,
  SubmitQuestionsAns,
  updateActiveQuestion,
  setTimerFlag,
  FetchOneQuestion,
  setProgressBarNavigation,
  setQuestionBar,
  setStartIndex,
} from "Redux/feature/Questions";
import OpenTextBox from "../QuestionTypes/OpenTextBox";
import ImageAndText from "../QuestionTypes/ImageandText";
import TextImageWithQuestion from "../QuestionTypes/TextImageWithQuestion";
import NumericBulletSingleChoice from "../QuestionTypes/NumericBulletSingleChoice";
import LongText from "../QuestionTypes/LongText";
import ImageQuestion from "../QuestionTypes/ImageQuestion";
import EnglishLayout from "../QuestionTypes/EnglishLayout";
import Quantitative from "../QuestionTypes/Quantative";
import VerbatimText from "../QuestionTypes/VerbatimText";
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Modal,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Close } from "@mui/icons-material";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import AssignmentDialog from "views/common/AssignmentDialog";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
} from "Redux/feature/StudentHome";
import SessionDialog from "views/common/SessionDialog";
import { getCookie } from "views/common/cookieUtils";
import NumericBulletforSingleQuestion from "../QuestionTypes/NumericBulletforSingleQuestion";

type Props = {};
type ModalSubmitData = {
  user_id: string;
  container_id: string;
  question_id: string;
  time_taken_for_answer: any;
};

const SingleQuestionType = (props: Props) => {
  const { questions, isLoading, renderPreview }: any = useAppSelector(
    (state) => state.Questions
  );
  const progressBarNavigationFlag: boolean = useAppSelector(
    (state) => state.Questions.progressBarNavigationFlag
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const [showResult, setShowResult] = useState<any>(false);
  const [showResultMode, setShowResultMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSerchParams] = useSearchParams();
  const [modalSubmitData, setModalSubmitData] = useState<ModalSubmitData>();
  const user_id = getCookie("id");
  const dispatch = useAppDispatch();
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(min-width: 900px)");

  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const questionBar: boolean = useAppSelector(
    (state) => state.Questions.questionBar
  );
  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openSessionSuccess
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );

  const assignment_id = searchParams.get("assignment_id");
  const atomicSideBar: any = searchParams.get("atomicSideBar");
  const defaultCourseId = getCookie("default_course_id");
  useEffect(() => {
    if (!allQuestionAttempt) {
      dispatch(setQuestionBar(false));
      dispatch(setProgressBarNavigation(false));
    }
  }, [allQuestionAttempt]);

  useEffect(() => {
    const question_id = searchParams.get("question_id");
    const collection_id = searchParams.get("collection_id");
    if (question_id || collection_id) {
      dispatch(
        FetchOneQuestion({
          question_id,
          collection_id,
          container_id: searchParams.get("container_id"),
        })
      );
    } else {
      dispatch(
        FetchQuestions({
          user_id: user_id ? user_id : null,
          container_id: searchParams.get("container_id"),
        })
      ).then((resp: any) => {});
    }
  }, [searchParams.get("container_id")]);
  const lesson = searchParams.get("lesson");

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  useEffect(() => {
    if (lesson == "1") {
      dispatch(setQuizQuestionComplete(true));
    }
  }, [lesson]);
  const handleAnswerSubmit = (data: any) => {
    if (activeQuestion + 1 == questions.length) {
      const meetingIndex = atomicUnitData?.findIndex((item: any) => {
        return item.meeting.assignment.some(
          (assignmentData: any) => assignmentData.id == assignment_id
        );
      });
      if (user_id) {
        dispatch(
          CompleteContainerAtomic({
            student_id: user_id,
            assignment_id: searchParams.get("assignment_id"),
            meeting_id: localStorage.getItem("meetingId")
              ? localStorage.getItem("meetingId")
              : atomicUnitData[meetingIndex]?.meeting?.id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,

            type: "container",
            id: searchParams.get("container_id"),
          })
        ).then((res: any) => {
          dispatch(
            FetchAtomicAndContainerData({
              id: user_id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,
            })
          );
        });
      }
    }
    setModalSubmitData(data);
    if (renderPreview) {
      dispatch(setQuizQuestionComplete(true));
    } else if (data.answer === undefined || data.answer === "") {
      setShowModal(true);
    } else {
      dispatch(
        SubmitQuestionsAns({
          user_id: data.user_id,
          container_id: data.container_id,
          assignment_id: searchParams.get("assignment_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          question_id: data.question_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          notes: "",
          time_taken_for_answer:
            data.time_taken_for_answer === "NaN:NaN"
              ? "00:00"
              : data.time_taken_for_answer,
        })
      ).then((res: any) => {
        setShowResult(true);
        dispatch(
          GetAllSubmittedQuestion({
            user_id: user_id,
            container_id: searchParams.get("container_id"),
          })
        ).then((res: any) => {});
        dispatch(setQuizQuestionComplete(true));
      });
    }
  };

  const currentQuestion = activeQuestion + 1;
  const totalQuestions = questions?.length;
  const handleSummaryPage = () => {
    if (activeQuestion === questions.length - 1) {
      dispatch(
        GetAllSubmittedQuestion({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
        })
      );
      dispatch(setQuizQuestionComplete(true));
      dispatch(setStartIndex(0));
      dispatch(updateActiveQuestion(0));
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`
      );
      dispatch(setQuestionBar(true));
    }
  };
  const handleSummaryPageAnswer = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: searchParams.get("container_id"),
      })
    );
    dispatch(setQuizQuestionComplete(true));
    dispatch(setStartIndex(0));
    dispatch(updateActiveQuestion(0));
    navigate(
      `/practice-summary?container_type_id=${searchParams.get(
        "container_type_id"
      )}&container_id=${searchParams.get(
        "container_id"
      )}&assignment_id=${searchParams.get("assignment_id")}`
    );
  };
  const handleNavigatenextAnswer = () => {
    dispatch(setQuestionBar(true));
    dispatch(setProgressBarNavigation(true));
    dispatch(updateActiveQuestion(activeQuestion + 1));
  };

  const handleIconCClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNextCheckAnswer = () => {
    handleSummaryPage();
    setShowResult(false);
    dispatch(setTimerFlag(true));
    dispatch(setProgressBarNavigation(false));
    dispatch(setQuizQuestionComplete(false));
    setShowResultMode(false);
    dispatch(setStartIndex(0));
    dispatch(updateActiveQuestion(activeQuestion + 1));
    if (activeQuestion === questions.length - 1) {
      dispatch(updateActiveQuestion(0));
      dispatch(setQuizQuestionComplete(true));
      dispatch(setStartIndex(0));
    }
  };

  const handleClosePopup = () => {
    setShowModal(false);
    setShowResult(false);
  };
  const practicesummary = () => {
    if (activeQuestion < totalQuestions - 1) {
      dispatch(setQuestionBar(true));
      dispatch(setProgressBarNavigation(true));
      dispatch(updateActiveQuestion(activeQuestion + 1));
    } else {
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`
      );
    }
  };
  const nextPage = () => {
    const totalQuestions = questions.length;
    dispatch(
      SubmitQuestionsAns({
        user_id: modalSubmitData?.user_id,
        container_id: modalSubmitData?.container_id,
        assignment_id: searchParams.get("assignment_id"),
        question_id: modalSubmitData?.question_id,
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        collection_id: "",
        answer: "",
        is_skipped_question: 1,
        notes: "",
        time_taken_for_answer:
          modalSubmitData?.time_taken_for_answer === "NaN:NaN"
            ? "00:00"
            : modalSubmitData?.time_taken_for_answer,
      })
    ).then((res: any) => {
      if (
        window.location.pathname === "/question-pages" &&
        searchParams.get("container_type_id") === "1"
      ) {
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(
          GetAllSubmittedQuestion({
            user_id: user_id,
            container_id: searchParams.get("container_id"),
          })
        );
        setShowResult(true);
        dispatch(setQuizQuestionComplete(true));
      }
    });
    if (activeQuestion < totalQuestions) {
      dispatch(updateActiveQuestion(activeQuestion + 1));
      setShowModal(false);
    } else {
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`
      );
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            margin: "0 auto",
            height: "calc(100vh - 71px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              maxWidth: "1230px",
              marginInline: "auto",
              minHeight: "29px",
              height: "fit-content",
            }}
          ></div>
          <AssignmentDialog openAssignmentSuccess={assignmentModal} />
          <SessionDialog openSessionSuccess={sessionModal} />
          {questions.map((item: any, index: any) => {
            return (
              <>
                {item.type?.id === 1 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <div>
                        <OpenTextBox
                          question={item.question}
                          onSubmit={handleAnswerSubmit}
                          question_id={item?.id}
                          timesPerQuestion={item.time_per_question}
                          showResultMode={showResultMode}
                          solving_explanation={item.solving_explanation}
                          explanation_image={item.explanation_image}
                        />
                      </div>
                    )}
                  </>
                )}
                {item.type?.id === 2 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <ImageAndText
                        question_id={item?.id}
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        is_completed={item.is_completed}
                        is_skipped_question={item.is_skipped_question}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                        horizontal={item.horizontal}
                        setIsCollapsed2={setIsCollapsed2}
                        explanation_image={item.explanation_image}
                      />
                    )}
                  </>
                )}
                {item.type?.id === 3 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <TextImageWithQuestion
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item?.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                        horizontal={item.horizontal}
                        setIsCollapsed2={setIsCollapsed2}
                        explanation_image={item.explanation_image}
                      />
                    )}
                  </>
                )}
                {item.type?.id === 4 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <NumericBulletforSingleQuestion
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item?.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                        horizontal={item.horizontal}
                        setIsCollapsed2={setIsCollapsed2}
                        setShowResultMode={setShowResultMode}
                        explanation_image={item.explanation_image}
                      />
                    )}
                  </>
                )}

                {item.type?.id === 5 && activeQuestion === index && (
                  <LongText />
                )}
                {item.type?.id === 6 && activeQuestion === index && (
                  <ImageQuestion />
                )}
                {item.type?.id === 7 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <EnglishLayout
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item?.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        explanation_image={item.explanation_image}
                        showResultMode={showResultMode}
                        horizontal={item.horizontal}
                        setIsCollapsed2={setIsCollapsed2}
                        type_id={item.type.id}
                      />
                    )}
                  </>
                )}
                {item.type?.id === 8 && activeQuestion === index && (
                  <Quantitative />
                )}
                {item.type?.id === 9 && activeQuestion === index && (
                  <VerbatimText />
                )}
                {item.type?.id === 10 && activeQuestion === index && (
                  <>
                    {isLoading ? (
                      <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        Loading
                      </h2>
                    ) : (
                      <EnglishLayout
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item?.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                        horizontal={item.horizontal}
                        setIsCollapsed2={setIsCollapsed2}
                        type_id={item.type.id}
                        explanation_image={item.explanation_image}
                      />
                    )}
                  </>
                )}
              </>
            );
          })}
          {showResult === true ||
          (!renderPreview && progressBarNavigationFlag) ? (
            <Box
              sx={
                atomicSideBar === false || atomicSideBar === null
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#FFFFFF",
                      height: "70px",
                      width: ["-webkit-fill-available", "-moz-available"],
                      position: "fixed",
                      bottom: "0",
                      zIndex: "0",
                      borderTop: "1px solid #DFE2E5",
                    }
                  : { display: "none" }
              }
            >
              <Button
                onClick={handleNextCheckAnswer}
                sx={{ m: "auto" }}
                variant="contained"
              >
                <FormattedMessage
                  id={
                    currentQuestion == questions.length
                      ? "done"
                      : "next-question"
                  }
                />
              </Button>
            </Box>
          ) : (
            <></>
          )}

          {questionBar ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  height: "70px",
                  width: ["-webkit-fill-available", "-moz-available"],
                  position: "fixed",
                  bottom: "0",
                  zIndex: "0",
                  borderTop: "1px solid #DFE2E5",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "7A49B9",
                    my: "15px",
                    width: "152px",
                    height: "40px",
                    padding: "9px 22px",
                    borderRadius: "8px",
                  }}
                  disabled={activeQuestion == questions.length - 1}
                  onClick={() => handleNavigatenextAnswer()}
                >
                  <FormattedMessage id="next-question" />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #7A49B9",
                    color: "#7A49B9",
                    my: "15px",
                    marginRight: "10px",
                    height: "40px",
                    padding: "10px 30px",
                    borderRadius: "8px",
                  }}
                  onClick={handleSummaryPageAnswer}
                >
                  <FormattedMessage id="done-researching" />
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
        {showResult === true ||
        (!renderPreview && progressBarNavigationFlag) ||
        progressBarNavigationFlag == true ||
        questionBar ? (
          <>
            {isCollapsed2 === true ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                    height: {
                      xs: "300px",
                      sm: "300px",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      height: "100%",
                    }}
                  >
                    <QuestionSideBar question_id={questions[activeQuestion]} />
                  </div>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      {showModal && (
        <Dialog
          open={showModal}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: { borderRadius: "30px" },
          }}
        >
          <Box
            style={{
              padding: "25px",
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button style={{ justifyContent: "end" }}>
              <Close onClick={handleClosePopup} />
            </Button>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="no-answer-popup-title" />
                </Typography>
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="modal-text" />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "block",
                paddingTop: "20px",
                marginInline: "auto",
              }}
            >
              <Stack direction="row" justifyContent="center" width="100%">
                <Button
                  onClick={handleClosePopup}
                  autoFocus
                  variant="contained"
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="no" />
                </Button>
                <Button
                  onClick={nextPage}
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="yes" />
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </Dialog>
      )}

      {allQuestionAttempt === true ? (
        <>
          {!renderPreview && (
            <>
              <Box
                className={`${
                  activeQuestion == 0 ? "question-option-mobile-4" : ""
                }`}
                sx={{
                  backgroundColor: "#FFFFFF",
                  boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
                  borderRadius: "0px 30px 30px 0px",
                  height: "35px",
                  width: "48px",
                  position: "absolute",
                  top: "135px",
                  left: "0",
                  textAlign: "center",
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton onClick={handleIconCClick}>
                  <EditIconCollaps />
                </IconButton>
              </Box>
              <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                sx={{
                  position: "fixed",
                  top: "78px",
                  right: "auto",
                  left: "0 !important",
                  height: "330px",
                  width: { xs: "350px", md: "300px" },
                  background: "#FFFFFF",
                  borderRadius: "0 30px 30px 0",
                  overflow: "hidden",
                }}
              >
                <div
                  className="modal-content"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "0 30px 30px 0",
                  }}
                >
                  <IconButton
                    onClick={handleIconCClick}
                    style={{ position: "absolute", left: "10px", top: "10px" }}
                  >
                    <EditIconCollapsModel />
                  </IconButton>
                  <ArrowRight
                    onClick={handleCloseModal}
                    style={{ position: "absolute", right: "10px", top: "15px" }}
                  />

                  <QuestionSideBar question_id={questions[activeQuestion]} />
                </div>
              </Modal>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SingleQuestionType;
