import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  TopicList,
  AddLessonsLearned,
  LessonsLearnedList,
} from "Redux/feature/LessonsSlice";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  GetPracticeSummary,
  setQuizQuestionComplete,
} from "Redux/feature/Questions";
import { getCookie } from "views/common/cookieUtils";
interface addLessonsProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  isRead?: any;
  row?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
  element: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    maxWidth: "484px",
    height: "552px",
    background: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    border: "1px solid #D0D4D9",
    borderRadius: "30px",
    padding: "45px",
    paddingBottom: "5px",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "335px",
      height: "600px",
      boxSizing: "border-box",
    },
  },
  secondElement: {
    position: "absolute" as "absolute",
    top: "53%",
    left: "50%",

    background: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    border: "1px solid #D0D4D9",
    borderRadius: "30px",
    padding: 3.75,
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "335px",
      boxSizing: "border-box",
    },
  },
  titleLabel: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#878E95",
    paddingTop: 1,
    textAlign: "right",
  },
  colorChangeSaveBtn: {
    color: "#AEB5BC",
    width: "87px",
    height: "37px",
    border: "1px solid #AEB5BC",
    borderRadius: "8px",
    "&:hover": { background: "#7A49B9" },
    [theme.breakpoints.down("md")]: {
      paddingTop: "9px",
      paddingRight: "20px",
      paddingBottom: "9px",
      paddingLeft: "20px",
      width: "125px",
    },
  },
  hideNumberArrow: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  setIframe: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "950px",
    },
  },
}));

function AddLessonsBook({ open, setOpen, isRead, row }: addLessonsProps) {
  const [topicId, setTopicId] = useState<string>("");
  const [writingLesson, setWritingLesson] = useState<string>("");
  const [questionCome, setQuestionCome] = useState<string>("");
  const [questionNumber, setQuestionsNumber] = useState<string>("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true);
  const { topicLists }: any = useAppSelector((state) => state.LessonsBook);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const defaultCourseId = getCookie("default_course_id");

  const handleClose = () => {
    setOpen(false);
    dispatch(setQuizQuestionComplete(false));
  };

  const user_id = getCookie("id");

  useEffect(() => {
    if (user_id) {
      dispatch(
        TopicList({
          student_id: user_id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      );
    }
  }, [user_id, defaultCourseId]);

  const handleChange = (event: SelectChangeEvent) => {
    setTopicId(event.target.value as string);

    if (writingLesson.length > 0) {
      setSubmitBtnDisabled(false);
    }
  };

  const handleSubmits = (e: any) => {
    e.preventDefault();
    if (user_id) {
      dispatch(
        AddLessonsLearned({
          student_id: user_id,
          topic_id: topicId,
          notes: writingLesson,
          where_question_come_from: questionCome,
          question_number: questionNumber,
          container_id: searchParams.get("container_id") || "",
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res) => {
        if (res) {
          setQuestionsNumber("");
          setQuestionCome("");
          if (searchParams.get("container_id")) {
            dispatch(
              GetPracticeSummary({
                user_id: user_id,
                container_id: searchParams.get("container_id"),
                course_id: defaultCourseId ? defaultCourseId : selectedCourse,
              })
            );
          } else {
            dispatch(
              LessonsLearnedList({
                student_id: user_id,
                course_id: defaultCourseId ? defaultCourseId : selectedCourse,

                per_page: 20,
                page: 1,
              })
            );
          }
        }
        setOpen(false);
        setSubmitBtnDisabled(true);
        setWritingLesson("");
        setTopicId("");
      });
    }
  };
  const getOpenQuestionsNumber = (e: any) => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value)) {
      setQuestionsNumber(e.target.value);
    }
  };
  const getOpenWritingLesson = (e: any) => {
    const newWritingLesson = e.target.value;
    setWritingLesson(newWritingLesson);

    if (newWritingLesson.length > 0) {
      if (topicId === "") {
        setSubmitBtnDisabled(true);
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      setSubmitBtnDisabled(true);
    }
  };
  const getOpenQuestionCome = (e: any) => {
    setQuestionCome(e.target.value);
  };

  const intl = useIntl();
  const questionNoTextFieldPlaceholder = intl.formatMessage({
    id: "questionNumber",
  });
  const questionComeFromTextFieldPlaceholder = intl.formatMessage({
    id: "questionComeFrom",
  });

  const writingLessonsTextFieldPlaceholder = intl.formatMessage({
    id: "lessonRecord",
  });
  const question = row?.question_id;
  let containerTypeId;

  if (row?.question?.question) {
    containerTypeId = 1;
  } else {
    containerTypeId = 4;
  }

  const serverUrl = process.env.REACT_APP_LMS_URL || "http://localhost:3000";
  const previewPath = `/preview/QuestionPages?container_type_id=${containerTypeId}&question_id=${question}&lesson=1`;
  return (
    <div>
      {isRead ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={`${classes.secondElement}`}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ color: "#7A49B9" }}
              sx={{
                position: "absolute",
                left: "25px",
                top: "25px",
                width: "24Px",
                height: "24px",
              }}
            >
              <Close />
            </IconButton>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#222529",
                paddingTop: 1,
                textAlign: "center",
              }}
            >
              {/* <FormattedMessage id="added-lesson" /> */}
              {/* <FormattedMessage id="preview" /> */}
            </Typography>
            <Box>
              <Grid container>
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  sx={{ margin: { xs: "0px", md: "30px" } }}
                >
                  <iframe
                    className={classes.setIframe}
                    src={`${serverUrl}${previewPath}`}
                    title="preview"
                    height="565px"
                    style={{
                      borderRadius: "0.7rem",
                      border: "none",
                      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)`,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={`${classes.element}`}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ color: "#7A49B9" }}
              sx={{
                position: "absolute",
                left: "25px",
                top: "25px",
                width: "24Px",
                height: "24px",
              }}
            >
              <Close />
            </IconButton>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#222529",
                paddingTop: 1,
                textAlign: "center",
              }}
            >
              <FormattedMessage id="added-lesson" />
            </Typography>
            <Box>
              <Typography className={classes.titleLabel}>
                <FormattedMessage id="questionComeFrom" />
              </Typography>
              <TextField
                value={questionCome}
                onChange={getOpenQuestionCome}
                placeholder={questionComeFromTextFieldPlaceholder}
                style={{ width: "100%", marginBottom: "20px" }}
              ></TextField>
              <Typography className={classes.titleLabel}>
                <FormattedMessage id="questionNumber" />
              </Typography>
              <TextField
                className={classes.hideNumberArrow}
                onChange={getOpenQuestionsNumber}
                value={questionNumber}
                placeholder={questionNoTextFieldPlaceholder}
                style={{ width: "100%", marginBottom: "20px" }}
              />
              <Typography className={classes.titleLabel}>
                <FormattedMessage id="topic" />
                <span style={{ color: "#B53C2F" }}>*</span>
              </Typography>
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownSharpIcon style={{ color: "#AEB5BC" }} />
                )}
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  paddingLeft: "16px",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={topicId}
                onChange={handleChange}
              >
                {topicLists?.map((row: any) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>

              <Typography className={classes.titleLabel}>
                <FormattedMessage id="writingLesson" />
                <span style={{ color: "#B53C2F" }}>*</span>
              </Typography>
              <TextareaAutosize
                onChange={getOpenWritingLesson}
                placeholder={writingLessonsTextFieldPlaceholder}
                style={{
                  resize: "none",
                  width: "100%",
                  marginBottom: "20px",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  marginTop: "3px",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  fontSize: "1rem",
                  padding: "10px",
                  color: "#000",
                  outline: "#7A49B9 !important",
                  height: "104px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                minRows={3}
              />

              <Button
                disabled={submitBtnDisabled}
                className={classes.colorChangeSaveBtn}
                style={{
                  alignItems: "center",
                  marginInline: "auto",
                  display: "flex",
                  fontWeight: "500",
                  fontSize: "16px",
                  backgroundColor: submitBtnDisabled
                    ? "#F1F3F5"
                    : "rgb(122, 73, 185)",
                  color: submitBtnDisabled ? "#AEB5BC" : "#fff",
                }}
                onClick={handleSubmits}
              >
                <FormattedMessage id="insight-save" />
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default AddLessonsBook;
