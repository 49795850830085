import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosClient from "api/api";

// Define interfaces for the data structure
interface Course {
  id: number;
  catalog_number: string;
  name: string;
  product_id: number;
  information_id: number;
  product_category_id: number | null;
  due_in: string;
  location: string;
  link: string;
  days: string;
  day_part: string;
  start: string;
  end: string;
  city: string | null;
  Month: string | null;
  Mozar: string | null;
  short_desc: string | null;
  start_date: string | null;
  end_date: string | null;
  year: string | null;
  created_at: string | null;
  updated_at: string;
  deleted_at: string | null;
  is_active: number;
}

interface Event {
  id: number;
  user_id: string;
  course_id: number;
  created_at: string | null;
  updated_at: string | null;
  courses: Course;
}

interface FetchCalenderEventPayload {
  events: Event[];
  work_groups: [];
}

interface InitialState {
  isLoading: boolean;
  error: string | null;
  events: Event[];
  work_groups: [];
}

// Update the type to include teacher_id
interface FetchCalenderEventParams {
  student_id?: string | undefined;
  teacher_id?: string | undefined;
  course_id?: string | undefined;
}

// Create the async thunk
export const FetchCalenderEvent = createAsyncThunk<
  FetchCalenderEventPayload,
  FetchCalenderEventParams
>(
  "StudentDiarySlice/getStudentsBasedOnCourse",
  async (data, { rejectWithValue }) => {
    try {
      let url = "get-calendar-events?";

      if (data.student_id) {
        url += `student_id=${data.student_id}`;
      }
      if (data.teacher_id) {
        url += `teacher_id=${data.teacher_id}`;
      }
      if (data.course_id) {
        if (data.student_id || data.teacher_id) {
          url += `&`;
        }
        url += `course_id=${data.course_id}`;
      }

      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Define the initial state
const initialState: InitialState = {
  isLoading: false,
  error: null,
  events: [],
  work_groups: [],
};

// Create the slice
const StudentDiarySlice = createSlice({
  name: "StudentDiarySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchCalenderEvent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        FetchCalenderEvent.fulfilled,
        (state, action: PayloadAction<FetchCalenderEventPayload>) => {
          state.isLoading = false;
          state.events = action.payload.events;
          state.work_groups = action.payload.work_groups;
        }
      )
      .addCase(
        FetchCalenderEvent.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.isLoading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export default StudentDiarySlice.reducer;
