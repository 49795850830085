import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

export const GetStudentManagementExamResult = createAsyncThunk(
  "StudentManagementExamResultSlice/getStudentManagementExamResult",
  async (data: {user_id:string | null, course_id:string, order_by_date?:string}) => {
    try {
      let apiUrl = `get-students-test-result?student_id=${data.user_id}&course_id=${data.course_id}`;

      if (data.order_by_date) {
        apiUrl += `&order_by_date=${data.order_by_date}`;
      }

      const res = await axiosClient.get(apiUrl);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchStudentManagementQuizResult = createAsyncThunk(
  "StudentManagementExamResultSlice/getStudentManagementQuizResult",
  async (data: {user_id:string | null,course_id?:string|null, per_page:number, page:number, order_by_date?:string}) => {
    try {
      let apiUrl = `get-quiz-result?user_id=${data.user_id}&per_page=${data.per_page}&page=${data.page}`;
      if (data.course_id) {
        apiUrl += `&course_id=${data.course_id}`;
      }
      if (data.order_by_date) {
        apiUrl += `&order_by_date=${data.order_by_date}`;
      }

      const res = await axiosClient.get(apiUrl);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  currentTab: number;
  error: any;
  product_category_id:number;
  getStudentManagementExamResult: any[];
  getStudentManagementQuizResult: any[];
  total: number;
}

const initialState: initialStage = {
  isLoading: false,
  error: null,
  product_category_id:0,
  getStudentManagementExamResult: [],
  getStudentManagementQuizResult: [],
  currentTab: 0,
  total: 0,
};

const StudentManagementExamResultSlice = createSlice({
  name: "StudentManagementExamResultSlice",
  initialState,
  reducers: {
    updateSelectedChapter: (state, action) => {
      return { ...state, selectedChapter: action.payload };
    },
    setCurrentTab: (state, action) => {
      return { ...state, currentTab: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetStudentManagementExamResult.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          product_category_id:0
        };
      })
      .addCase(GetStudentManagementExamResult.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getStudentManagementExamResult: action.payload.data,
          product_category_id: action.payload.product_category,
        };
      })
      .addCase(GetStudentManagementExamResult.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchStudentManagementQuizResult.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchStudentManagementQuizResult.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getStudentManagementQuizResult: action.payload.data.data,
          total: action.payload.data.total,
        };
      })
      .addCase(FetchStudentManagementQuizResult.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export const { updateSelectedChapter, setCurrentTab } =
  StudentManagementExamResultSlice.actions;

export default StudentManagementExamResultSlice.reducer;
