import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  setQuizQuestionComplete,
  updateActiveQuestion,
  setTimerFlag,
  FetchQuestionsOnlineExam,
  replacePendingQuestions,
  SubmitQuestionsAnsForOnlineExam,
  GetAllSubmittedQuestionForOnlineExam,
  setProgressBarNavigation,
  GetPracticeSummary,
  setStartIndex,
} from "Redux/feature/Questions";
import OpenTextBox from "views/QuestionPage/QuestionTypes/OpenTextBox";
import ImageAndText from "views/QuestionPage/QuestionTypes/ImageandText";
import TextImageWithQuestion from "views/QuestionPage/QuestionTypes/TextImageWithQuestion";
import NumericBulletSingleChoice from "views/QuestionPage/QuestionTypes/NumericBulletSingleChoice";
import LongText from "views/QuestionPage/QuestionTypes/LongText";
import ImageQuestion from "views/QuestionPage/QuestionTypes/ImageQuestion";
import EnglishLayout from "views/QuestionPage/QuestionTypes/EnglishLayout";
import VerbatimText from "views/QuestionPage/QuestionTypes/VerbatimText";
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Modal,
  DialogContent,
  Alert,
} from "@mui/material";
import QuestionSideBar from "views/QuestionPage/Sidebar/QuestionSideBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Close } from "@mui/icons-material";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  updateCluster,
  updateQuestionData,
  updateSidebarData,
} from "Redux/feature/onlinePracticeExamSlice";
import { useSearchParams } from "react-router-dom";
import Quantitative from "views/QuestionPage/QuestionTypes/Quantative";
import { getCookie } from "views/common/cookieUtils";
import NumericBulletforSingleQuestion from "views/QuestionPage/QuestionTypes/NumericBulletforSingleQuestion";

type Props = {};
type ModalSubmitData = {
  user_id: string;
  container_id: string;
  question_id: string;
  time_taken_for_answer: number;
  difficulty_level: number;
};

const NewPracticeSLQuestionPage = (props: Props) => {
  const { questions, renderPreview }: any = useAppSelector(
    (state) => state.Questions
  );
  const progressBarNavigationFlag: boolean = useAppSelector(
    (state) => state.Questions.progressBarNavigationFlag
  );
  const [searchParams, setSerchParams] = useSearchParams();
  const [showResult, setShowResult] = useState<any>(false);
  const [showResultMode, setShowResultMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalSubmitData, setModalSubmitData] = useState<ModalSubmitData>();
  const user_id = getCookie("id");
  const dispatch = useAppDispatch();
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [levelStatus, setLevelStatus] = useState("");
  const isSmallScreen = useMediaQuery("(min-width: 900px)");
  const defaultCourseId = getCookie("default_course_id");

  const navigate = useNavigate();
  const { isLoading }: any = useAppSelector((state) => state.Questions);
  let selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );

  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const allQuestionContainerID = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo.id
  );
  const questionData = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.questionData
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const is_cluster = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo?.is_cluster
  );
  const cluster = searchParams.get("is_cluster");
  const ActivePluse: number = activeQuestion + 1;

  const ActiveQuestionDifficultLevel = useAppSelector(
    (state) => state.Questions?.questions[activeQuestion]?.difficulty_level
  );
  const errorMessage = useAppSelector((state) => state.Questions.error);

  const min_level = useAppSelector((state) => state.Questions.min_level);
  const max_level = useAppSelector((state) => state.Questions.max_level);
  const up_btn_disable = useAppSelector(
    (state) => state.Questions.up_btn_disable
  );
  const down_btn_disable = useAppSelector(
    (state) => state.Questions.down_btn_disable
  );

  useEffect(() => {
    if (!allQuestionAttempt) {
      dispatch(updateQuestionData(false));
    }
  }, [allQuestionAttempt]);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  useEffect(() => {
    if (questionData && user_id) {
      dispatch(
        GetPracticeSummary({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((resp: any) => {
        if (resp.payload.status == true) {
          const arr = resp?.payload?.data?.sidebar_data;
          dispatch(updateSidebarData(arr));
        } else {
          dispatch(updateSidebarData([]));
        }
      });
      return () => {
        dispatch(updateSidebarData([]));
      };
    }
  }, [questionData]);

  useEffect(() => {
    if (allQuestionContainerID && !questionData && user_id) {
      try {
        dispatch(
          FetchQuestionsOnlineExam({
            student_id: user_id,
            practice_id: allQuestionContainerID,
            onlineExamType: "practice",
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((resp: any) => {
          if (resp?.payload?.status === true) {
            setShowErrorMessage(false);
          } else {
            setShowErrorMessage(true);
          }
        });
      } catch (error) {
        console.error(
          "Error while dispatching FetchQuestionsOnlineExam:",
          error
        );
        setShowErrorMessage(true);
      }
    } else {
      if (!questionData) {
        navigate(`/student/library`, {
          state: { fromQuestionPageOfNewPractice: true },
        });
      }
    }
  }, [allQuestionContainerID, !questionData]);

  useEffect(() => {
    if (!showResult) {
      setShowErrorMessage(false);
    }
  }, [showResult]);

  const handleAnswerSubmit = (data: any) => {
    setModalSubmitData(data);
    if (renderPreview) {
      dispatch(setQuizQuestionComplete(true));
    } else if (data.answer === undefined || data.answer === "") {
      setShowModal(true);
    } else {
      dispatch(
        SubmitQuestionsAnsForOnlineExam({
          student_id: data.user_id,
          practice_id: allQuestionContainerID,
          question_id: data.question_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          notes: "",
          level: ActiveQuestionDifficultLevel,
          time_taken_for_answer: data.time_taken_for_answer,
          onlineExamType: "practice",
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res: any) => {
        setShowResult(true);
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: user_id,
            practice_id: allQuestionContainerID,
            onlineExamType: "practice",
          })
        ).then((res: any) => {});
        dispatch(setQuizQuestionComplete(true));
      });
    }
  };

  const handleSummaryPage = () => {
    if (activeQuestion === questions.length - 1) {
      dispatch(
        GetAllSubmittedQuestionForOnlineExam({
          student_id: user_id,
          practice_id: allQuestionContainerID,
          onlineExamType: "practice",
        })
      );
      dispatch(setQuizQuestionComplete(true));
      dispatch(setStartIndex(0));
      dispatch(updateActiveQuestion(0));
      navigate(
        `/practice-summary?container_type_id=${7}&container_id=${allQuestionContainerID}`
      );
      dispatch(updateCluster(-1));
      dispatch(updateQuestionData(true));
    }
  };

  const handleIconCClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function filterAndAddKeys(data: any[], questionData: any[]): any[] {
    const newData = data.map((question: any) => {
      const matchingQuestion = questionData?.find(
        (q: any) => q?.question_id === question?.id
      );
      if (matchingQuestion) {
        return {
          ...question,
          is_correct_answer: matchingQuestion?.is_correct_answer,
          is_skipped_question: matchingQuestion?.is_skipped_question,
          time_taken_for_answer: matchingQuestion?.time_taken_for_answer,
        };
      }

      return question;
    });

    return newData;
  }

  const handleNextCheckAnswer = () => {
    setLevelStatus("");
    handleSummaryPage();
    setShowResult(false);
    dispatch(setTimerFlag(true));
    dispatch(setQuizQuestionComplete(false));
    setShowResultMode(false);
    dispatch(updateActiveQuestion(ActivePluse));
    if (activeQuestion === questions.length - 1) {
      dispatch(updateActiveQuestion(0));
    }
  };
  const handleNextCheckAnswerUpLevel = () => {
    setLevelStatus("up");
    if (ActiveQuestionDifficultLevel !== "10" && user_id) {
      // handleSummaryPage();
      // setShowResult(false);
      // dispatch(setTimerFlag(true));
      // dispatch(setQuizQuestionComplete(false));
      // setShowResultMode(false);
      // dispatch(updateActiveQuestion(ActivePluse));

      try {
        dispatch(
          FetchQuestionsOnlineExam({
            student_id: user_id,
            practice_id: allQuestionContainerID,
            level_status: "up",
            level: ActiveQuestionDifficultLevel,
            question_index: ActivePluse,
            onlineExamType: "practice",
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
            min_level,
            max_level,
          })
        )
          .then((res: any) => {
            if (
              res &&
              res.payload &&
              res.payload.status === true &&
              res.payload.data.length > 0
            ) {
              const errorMassage: boolean = res?.payload?.message?.includes(
                "אין מספיק שאלות בנושא כדי לשנות את הרמה"
              );

              if (errorMassage) {
                setShowErrorMessage(true);
                setShowResult(true);
              } else {
                setShowErrorMessage(false);
                setShowResult(false);
                handleSummaryPage();
                setShowResult(false);
                dispatch(setTimerFlag(true));
                dispatch(setQuizQuestionComplete(false));
                setShowResultMode(false);
                dispatch(updateActiveQuestion(ActivePluse));
              }

              if (!errorMassage) {
                const activeQuestionIndex: any = ActivePluse;
                const newQuestions = filterAndAddKeys(
                  res.payload.data,
                  res.payload.question_data
                );

                dispatch(
                  replacePendingQuestions({
                    oldQuestions: questions,
                    newQuestions: newQuestions,
                    activeQuestion: activeQuestionIndex,
                    isLoading: isLoading,
                  })
                );
              }
            } else {
              setShowErrorMessage(true);
            }
          })
          .catch((error) => {
            console.error("Error occurred while fetching questions:", error);
            setShowErrorMessage(true);
          });
      } catch (error) {
        console.error("Error occurred while dispatching:", error);
        setShowErrorMessage(true);
      }
    }
    if (activeQuestion === questions.length - 1) {
      dispatch(updateActiveQuestion(0));
    }
  };

  const handleNextCheckAnswerDownLevel = () => {
    setLevelStatus("down");
    if (ActiveQuestionDifficultLevel !== "1" && user_id) {
      // handleSummaryPage();
      // dispatch(setTimerFlag(true));
      // dispatch(setQuizQuestionComplete(false));
      // setShowResultMode(false);
      // dispatch(updateActiveQuestion(ActivePluse));
      // setShowErrorMessage(false);

      try {
        dispatch(
          FetchQuestionsOnlineExam({
            student_id: user_id,
            practice_id: allQuestionContainerID,
            level_status: "down",
            level: ActiveQuestionDifficultLevel,
            question_index: ActivePluse,
            onlineExamType: "practice",
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
            min_level,
            max_level,
          })
        ).then((res) => {
          if (res?.payload?.status === true) {
            // setShowResult(false);

            const errorMassage: boolean = res?.payload?.message?.includes(
              "אין מספיק שאלות בנושא כדי לשנות את הרמה"
            );
            if (errorMassage)  {
              setShowErrorMessage(true);
              setShowResult(true);
            } else {
              setShowErrorMessage(false);
              setShowResult(false);
              handleSummaryPage();
              dispatch(setTimerFlag(true));
              dispatch(setQuizQuestionComplete(false));
              setShowResultMode(false);
              dispatch(updateActiveQuestion(ActivePluse));
              setShowErrorMessage(false);
            }

            if (!errorMassage) {
              const activeQuestionIndex: any = ActivePluse;
              const newQuestions = filterAndAddKeys(
                res?.payload?.data,
                res?.payload?.question_data
              );
              dispatch(
                replacePendingQuestions({
                  oldQuestions: questions,
                  newQuestions: newQuestions,
                  activeQuestion: activeQuestionIndex,
                  isLoading: isLoading,
                })
              );
            }
          } else {
            setShowErrorMessage(true);
          }
        });
      } catch (error) {
        console.error("An error occurred during dispatch:", error);
        setShowErrorMessage(true);
      }
    }
    if (activeQuestion === questions.length - 1) {
      dispatch(updateActiveQuestion(0));
    }
  };

  const handleClosePopup = () => {
    setShowModal(false);
    setShowResult(false);
  };

  const nextPage = () => {
    const totalQuestions = questions.length;
    dispatch(
      SubmitQuestionsAnsForOnlineExam({
        student_id: modalSubmitData?.user_id,
        practice_id: allQuestionContainerID,
        question_id: modalSubmitData?.question_id,
        collection_id: "",
        answer: "",
        is_skipped_question: 1,
        notes: "",
        level: ActiveQuestionDifficultLevel,
        time_taken_for_answer: modalSubmitData?.time_taken_for_answer,
        onlineExamType: "practice",
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
      })
    ).then((resp) => {
      if (activeQuestion < totalQuestions) {
        setShowResult(true);
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: modalSubmitData?.user_id,
            practice_id: allQuestionContainerID,
            onlineExamType: "practice",
          })
        );
        dispatch(setQuizQuestionComplete(true));
        dispatch(updateActiveQuestion(ActivePluse - 1));
        setShowModal(false);
      } else {
        navigate(`/student/library`, {
          state: { fromQuestionPageOfNewPractice: true },
        });
      }
    });
  };
  const Data: any = useAppSelector(
    (state) => state.Questions.submitQuestionsAnswer
  );

  const setbuttons = useMediaQuery(
    "(min-height: 700px) and (min-width:1200px)"
  );
  const handleSummaryPageAnswer = () => {
    dispatch(
      GetAllSubmittedQuestionForOnlineExam({
        student_id: user_id,
        practice_id: allQuestionContainerID,
        onlineExamType: "practice",
      })
    );
    dispatch(setQuizQuestionComplete(true));
    dispatch(setStartIndex(0));
    dispatch(updateActiveQuestion(0));
    if (cluster) {
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get("container_id")}&is_cluster=${true}`
      );
    }
    navigate(
      `/practice-summary?container_type_id=${searchParams.get(
        "container_type_id"
      )}&container_id=${searchParams.get("container_id")}`
    );
  };
  const handleNavigatenextAnswer = () => {
    dispatch(updateQuestionData(true));
    dispatch(setProgressBarNavigation(true));
    dispatch(updateActiveQuestion(activeQuestion + 1));
  };
  return (
    <>
      {isLoading && (
        <h2
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          Loading...
        </h2>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            margin: "0 auto",
            height: "calc(100vh - 71px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              maxWidth: "1230px",
              marginInline: "auto",
              minHeight: "29px",
              height: "fit-content",
            }}
          ></div>
          {questions.map((item: any, index: any) => {
            return (
              <>
                {item.type?.id === 1 && activeQuestion === index && (
                  <div>
                    <OpenTextBox
                      question={item.question}
                      onSubmit={handleAnswerSubmit}
                      question_id={item?.id}
                      timesPerQuestion={item.time_per_question}
                      showResultMode={showResultMode}
                      solving_explanation={item.solving_explanation}
                      explanation_image={item.explanation_image}
                    />
                  </div>
                )}
                {item.type?.id === 2 && activeQuestion === index && (
                  <ImageAndText
                    question_id={item?.id}
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    is_completed={item.is_completed}
                    is_skipped_question={item.is_skipped_question}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 3 && activeQuestion === index && (
                  <TextImageWithQuestion
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 4 && activeQuestion === index && (
                  <NumericBulletforSingleQuestion
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    setShowResultMode={setShowResultMode}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 5 && activeQuestion === index && (
                  <LongText />
                )}
                {item.type?.id === 6 && activeQuestion === index && (
                  <ImageQuestion />
                )}
                {item.type?.id === 7 && activeQuestion === index && (
                  <EnglishLayout
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    type_id={item.type.id}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 8 && activeQuestion === index && (
                  <Quantitative />
                )}
                {item.type?.id === 9 && activeQuestion === index && (
                  <VerbatimText />
                )}
                {item.type?.id === 10 && activeQuestion === index && (
                  <EnglishLayout
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    type_id={item.type.id}
                    explanation_image={item.explanation_image}
                  />
                )}
              </>
            );
          })}
          {questionData ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  height: "70px",
                  width: ["-webkit-fill-available", "-moz-available"],
                  position: "fixed",
                  bottom: "0",
                  zIndex: "0",
                  borderTop: "1px solid #DFE2E5",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "7A49B9",
                    my: "15px",
                    width: "152px",
                    height: "40px",
                    padding: "9px 22px",
                    borderRadius: "8px",
                  }}
                  disabled={activeQuestion == questions.length - 1}
                  onClick={() => handleNavigatenextAnswer()}
                >
                  <FormattedMessage id="next-question" />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #7A49B9",
                    color: "#7A49B9",
                    my: "15px",
                    marginRight: "10px",
                    height: "40px",
                    padding: "10px 30px",
                    borderRadius: "8px",
                  }}
                  onClick={handleSummaryPageAnswer}
                >
                  <FormattedMessage id="done-researching" />
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
          {showResult === true ||
          (!renderPreview && progressBarNavigationFlag) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                height: "70px",
                width: ["-webkit-fill-available", "-moz-available"],
                position: "fixed",
                bottom: "0",
                zIndex: "0",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: setbuttons ? "50%" : "100%",
                }}
              >
                <Button
                  onClick={handleNextCheckAnswerDownLevel}
                  sx={{ marginLeft: "20px" }}
                  variant={
                    activeQuestion === questions.length - 1
                      ? "contained"
                      : Data?.is_correct_answer_given !== 1
                      ? "contained"
                      : "outlined"
                  }
                  disabled={
                    is_cluster == 1 ||
                    down_btn_disable == 1 ||
                    ActiveQuestionDifficultLevel === "1" ||
                    activeQuestion === questions.length - 1 ||
                    (errorMessage?.includes(
                      "אין מספיק שאלות בנושא כדי לשנות את הרמה"
                    ) &&
                      levelStatus === "down")
                  }
                >
                  <FormattedMessage id="down-level" />
                </Button>
                <Button
                  onClick={handleNextCheckAnswer}
                  sx={{ marginLeft: "20px" }}
                  variant="contained"
                >
                  {activeQuestion === questions.length - 1 ? (
                    <>
                      <FormattedMessage id="end-of-practice" />
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="next-question" />
                    </>
                  )}
                </Button>
                <Button
                  onClick={handleNextCheckAnswerUpLevel}
                  sx={{ marginLeft: "20px" }}
                  variant={
                    activeQuestion === questions.length - 1
                      ? "contained"
                      : ActiveQuestionDifficultLevel === "10"
                      ? "contained"
                      : Data?.is_correct_answer_given === 1
                      ? "contained"
                      : "outlined"
                  }
                  disabled={
                    is_cluster == 1 ||
                    up_btn_disable == 1 ||
                    ActiveQuestionDifficultLevel === "10" ||
                    activeQuestion === questions.length - 1 ||
                    (errorMessage?.includes(
                      "אין מספיק שאלות בנושא כדי לשנות את הרמה"
                    ) &&
                      levelStatus === "up")
                  }
                >
                  <FormattedMessage id="level-up" />
                </Button>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </div>
        {showResult === true ||
        (!renderPreview && progressBarNavigationFlag) ||
        progressBarNavigationFlag === true ||
        questionData ? (
          <>
            {isCollapsed2 === true ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                    height: {
                      xs: "300px",
                      sm: "300px",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      height: "100%",
                    }}
                  >
                    <QuestionSideBar question_id={questions[activeQuestion]} />
                  </div>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      {showErrorMessage === true && (
        <Alert
          className="removeIconLessonBooksAlert"
          sx={{
            width: "auto !important",
            backgroundColor: "#E74C3C",
            color: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            fontWeight: 500,
            fontSize: "16px",
            position: "absolute",
            left: "35%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            bottom: "90px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "16px", fontWeight: 500, color: "#FFF" }}>
              {errorMessage}
            </span>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "10px" }}
              onClick={() => setShowErrorMessage(false)}
            >
              <path
                d="M0.93934 16.9393C0.353553 17.5251 0.353553 18.4749 0.93934 19.0607C1.52513 19.6464 2.47487 19.6464 3.06066 19.0607L10 12.1213L16.9393 19.0607C17.5251 19.6465 18.4749 19.6465 19.0607 19.0607C19.6464 18.4749 19.6464 17.5251 19.0607 16.9393L12.1213 10L19.0607 3.06066C19.6464 2.47487 19.6464 1.52513 19.0607 0.93934C18.4749 0.353553 17.5251 0.353553 16.9393 0.93934L10 7.87868L3.06066 0.939342C2.47487 0.353556 1.52513 0.353556 0.93934 0.939342C0.353553 1.52513 0.353553 2.47488 0.93934 3.06066L7.87868 10L0.93934 16.9393Z"
                fill="#FFF"
              />
            </svg> */}
          </div>{" "}
        </Alert>
      )}

      {showModal && (
        <Dialog
          open={showModal}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: { borderRadius: "30px" },
          }}
        >
          <Box
            style={{
              padding: "25px",
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button style={{ justifyContent: "end" }}>
              <Close onClick={handleClosePopup} />
            </Button>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="no-answer-popup-title" />
                </Typography>
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="modal-text" />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "block",
                paddingTop: "20px",
                marginInline: "auto",
              }}
            >
              <Stack direction="row" justifyContent="center" width="100%">
                <Button
                  onClick={handleClosePopup}
                  autoFocus
                  variant="contained"
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="no" />
                </Button>
                <Button
                  onClick={nextPage}
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="yes" />
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      {allQuestionAttempt === true ? (
        <>
          <Box
            className={`${
              activeQuestion == 0 ? " question-option-mobile-4" : ""
            }`}
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
              borderRadius: "0px 30px 30px 0px",
              height: "35px",
              width: "48px",
              position: "absolute",
              top: "135px",
              left: "0",
              textAlign: "center",
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleIconCClick}>
              <EditIconCollaps />
            </IconButton>
          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              position: "fixed",
              top: "78px",
              right: "auto",
              left: "0 !important",
              height: "330px",
              width: { xs: "350px", md: "300px" },
              background: "#FFFFFF",
              // border: "1px solid #DFE2E5",
              borderRadius: "0 30px 30px 0",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                borderRadius: "0 30px 30px 0",
                overflow: "hidden",
              }}
            >
              <IconButton
                onClick={handleIconCClick}
                style={{ position: "absolute", left: "10px", top: "10px" }}
              >
                <EditIconCollapsModel />
              </IconButton>
              <ArrowRight
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "10px", top: "15px" }}
              />

              <QuestionSideBar question_id={questions[activeQuestion]} />
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewPracticeSLQuestionPage;
