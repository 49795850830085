import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  setQuizQuestionComplete,
  updateActiveQuestion,
  setTimerFlag,
  FetchQuestionsOnlineExam,
  SubmitQuestionsAnsForOnlineExam,
  GetAllSubmittedQuestionForOnlineExam,
  GetPracticeSummary,
  setProgressBarNavigation,
  setStartIndex,
} from "Redux/feature/Questions";
import OpenTextBox from "views/QuestionPage/QuestionTypes/OpenTextBox";
import ImageAndText from "views/QuestionPage/QuestionTypes/ImageandText";
import TextImageWithQuestion from "views/QuestionPage/QuestionTypes/TextImageWithQuestion";
import NumericBulletSingleChoice from "views/QuestionPage/QuestionTypes/NumericBulletSingleChoice";
import LongText from "views/QuestionPage/QuestionTypes/LongText";
import ImageQuestion from "views/QuestionPage/QuestionTypes/ImageQuestion";
import EnglishLayout from "views/QuestionPage/QuestionTypes/EnglishLayout";
import Quantitative from "views/QuestionPage/QuestionTypes/Quantative";
import VerbatimText from "views/QuestionPage/QuestionTypes/VerbatimText";
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Modal,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import QuestionSideBar from "views/QuestionPage/Sidebar/QuestionSideBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Close } from "@mui/icons-material";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import {
  updateQuestionData,
  updateSidebarData,
} from "Redux/feature/onlinePracticeExamSlice";
import { useSearchParams } from "react-router-dom";
import { getCookie } from "views/common/cookieUtils";
import NumericBulletforSingleQuestion from "views/QuestionPage/QuestionTypes/NumericBulletforSingleQuestion";

type Props = {};
type ModalSubmitData = {
  user_id: string;
  container_id: string;
  question_id: string;
  time_taken_for_answer: number;
  difficulty_level: number;
};

const TheOvenSLQuestionPage = (props: Props) => {
  const { questions, renderPreview }: any = useAppSelector(
    (state) => state.Questions
  );
  const progressBarNavigationFlag: boolean = useAppSelector(
    (state) => state.Questions.progressBarNavigationFlag
  );
  const [showResult, setShowResult] = useState<any>(false);
  const [searchParams, setSerchParams] = useSearchParams();
  const [showResultMode, setShowResultMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalSubmitData, setModalSubmitData] = useState<ModalSubmitData>();
  const user_id = getCookie("id");
  const dispatch = useAppDispatch();
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(min-width: 900px)");

  let selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );

  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const allQuestionContainerID = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo.id
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const questionData = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.questionData
  );
  const ActivePluse: number = activeQuestion + 1;
  const defaultCourseId = getCookie("default_course_id");

  const activeQuestionDifficultLevel = useAppSelector(
    (state) => state.Questions?.questions[activeQuestion]?.difficulty_level
  );
  useEffect(() => {
    if (!allQuestionAttempt) {
      dispatch(updateQuestionData(false));
    }
  }, [allQuestionAttempt]);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  useEffect(() => {
    if (questionData && user_id) {
      dispatch(
        GetPracticeSummary({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((resp: any) => {
        if (resp.payload.status == true) {
          const arr = resp?.payload?.data?.sidebar_data;
          dispatch(updateSidebarData(arr));
        } else {
          dispatch(updateSidebarData([]));
        }
      });
      return () => {
        dispatch(updateSidebarData([]));
      };
    }
  }, [questionData]);

  useEffect(() => {
    if (allQuestionContainerID && !questionData && user_id) {
      dispatch(
        FetchQuestionsOnlineExam({
          student_id: user_id,
          practice_id: allQuestionContainerID,
          onlineExamType: "oven",
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((resp: any) => {});
    } else {
      if (!questionData) {
        navigate(`/student/library`, {
          state: { fromQuestionPageOfNewPractice: true },
        });
      }
    }
  }, [allQuestionContainerID, !questionData]);

  const handleAnswerSubmit = (data: any) => {
    setModalSubmitData(data);
    if (renderPreview) {
      dispatch(setQuizQuestionComplete(true));
    } else if (data.answer === undefined || data.answer === "") {
      setShowModal(true);
    } else {
      dispatch(
        SubmitQuestionsAnsForOnlineExam({
          student_id: data.user_id,
          practice_id: allQuestionContainerID,
          question_id: data.question_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          notes: "",
          level: activeQuestionDifficultLevel,
          time_taken_for_answer: data.time_taken_for_answer,
          onlineExamType: "oven",
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res: any) => {
        setShowResult(true);
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: user_id,
            practice_id: allQuestionContainerID,
            onlineExamType: "oven",
          })
        ).then((res: any) => {});
        dispatch(setQuizQuestionComplete(true));
      });
    }
  };

  const handleSummaryPage = () => {
    if (activeQuestion === questions.length - 1) {
      dispatch(
        GetAllSubmittedQuestionForOnlineExam({
          student_id: user_id,
          practice_id: allQuestionContainerID,
          onlineExamType: "oven",
        })
      );
      dispatch(setQuizQuestionComplete(true));
      dispatch(setStartIndex(0));
      dispatch(updateActiveQuestion(0));
      navigate(
        `/practice-summary?container_type_id=${8}&container_id=${allQuestionContainerID}`
      );
      dispatch(updateQuestionData(true));
    }
  };

  const handleIconCClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleNextCheckAnswer = () => {
    handleSummaryPage();
    setShowResult(false);
    dispatch(setTimerFlag(true));
    dispatch(setQuizQuestionComplete(false));
    setShowResultMode(false);
    dispatch(updateActiveQuestion(ActivePluse));
    if (activeQuestion === questions.length - 1) {
      dispatch(updateActiveQuestion(0));
    }
  };

  const handleClosePopup = () => {
    setShowModal(false);
    setShowResult(false);
  };
  const nextPage = () => {
    const totalQuestions = questions.length;
    dispatch(
      SubmitQuestionsAnsForOnlineExam({
        student_id: modalSubmitData?.user_id,
        practice_id: allQuestionContainerID,
        question_id: modalSubmitData?.question_id,
        collection_id: "",
        answer: "",
        is_skipped_question: 1,
        notes: "",
        level: activeQuestionDifficultLevel,
        time_taken_for_answer: modalSubmitData?.time_taken_for_answer,
        onlineExamType: "oven",
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
      })
    ).then((resp) => {
      if (activeQuestion < totalQuestions) {
        setShowResult(true);
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: modalSubmitData?.user_id,
            practice_id: allQuestionContainerID,
            onlineExamType: "oven",
          })
        );
        dispatch(setQuizQuestionComplete(true));
        dispatch(updateActiveQuestion(ActivePluse - 1));
        setShowModal(false);
      } else {
        navigate(`/student/library`, {
          state: { fromQuestionPageOfTheOven: true },
        });
      }
    });
  };
  const currentQuestion = activeQuestion + 1;
  const totalQuestions = questions?.length;
  const practiceSummary = () => {
    if (activeQuestion < totalQuestions - 1) {
      dispatch(updateQuestionData(true));
      dispatch(setProgressBarNavigation(true));
      dispatch(updateActiveQuestion(activeQuestion + 1));
    } else {
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get("container_id")}`
      );
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            margin: "0 auto",
            height: "calc(100vh - 71px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              maxWidth: "1230px",
              marginInline: "auto",
              minHeight: "29px",
              height: "fit-content",
            }}
          ></div>
          {questions.map((item: any, index: any) => {
            return (
              <>
                {item.type?.id === 1 && activeQuestion === index && (
                  <div>
                    <OpenTextBox
                      question={item.question}
                      onSubmit={handleAnswerSubmit}
                      question_id={item?.id}
                      timesPerQuestion={item.time_per_question}
                      showResultMode={showResultMode}
                      solving_explanation={item.solving_explanation}
                      explanation_image={item.explanation_image}
                    />
                  </div>
                )}
                {item.type?.id === 2 && activeQuestion === index && (
                  <ImageAndText
                    question_id={item?.id}
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    is_completed={item.is_completed}
                    is_skipped_question={item.is_skipped_question}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 3 && activeQuestion === index && (
                  <TextImageWithQuestion
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 4 && activeQuestion === index && (
                  <NumericBulletforSingleQuestion
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    setShowResultMode={setShowResultMode}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 5 && activeQuestion === index && (
                  <LongText />
                )}
                {item.type?.id === 6 && activeQuestion === index && (
                  <ImageQuestion />
                )}
                {item.type?.id === 7 && activeQuestion === index && (
                  <EnglishLayout
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    type_id={item.type.id}
                    explanation_image={item.explanation_image}
                  />
                )}
                {item.type?.id === 8 && activeQuestion === index && (
                  <Quantitative />
                )}
                {item.type?.id === 9 && activeQuestion === index && (
                  <VerbatimText />
                )}
                {item.type?.id === 10 && activeQuestion === index && (
                  <EnglishLayout
                    question={item.question}
                    option_images={item.option_images}
                    options={item.options}
                    image={item.image}
                    question_id={item?.id}
                    onSubmit={handleAnswerSubmit}
                    timesPerQuestion={item.time_per_question}
                    collection={item.collections}
                    solving_explanation={item.solving_explanation}
                    showResultMode={showResultMode}
                    horizontal={item.horizontal}
                    setIsCollapsed2={setIsCollapsed2}
                    type_id={item.type.id}
                    explanation_image={item.explanation_image}
                  />
                )}
              </>
            );
          })}
          {questionData ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                height: "70px",
                width: ["-webkit-fill-available", "-moz-available"],
                position: "fixed",
                bottom: "0",
                zIndex: "0",
              }}
            >
              <Button
                onClick={practiceSummary}
                sx={{ m: "auto" }}
                variant="contained"
              >
                <FormattedMessage
                  id={
                    currentQuestion == questions.length
                      ? "done"
                      : "next-question"
                  }
                />
              </Button>
            </Box>
          ) : (
            <></>
          )}
          {showResult === true ||
          (!renderPreview && progressBarNavigationFlag) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                height: "70px",
                width: ["-webkit-fill-available", "-moz-available"],
                position: "fixed",
                bottom: "0",
                zIndex: "0",
                gap: "20px",
              }}
            >
              <Button
                onClick={handleNextCheckAnswer}
                sx={{ marginX: "20px" }}
                variant="contained"
              >
                {activeQuestion !== questions.length - 1 ? (
                  <>
                    <FormattedMessage id="next-question" />
                  </>
                ) : (
                  <>
                    <FormattedMessage id="end-of-practice" />
                  </>
                )}
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </div>
        {showResult === true ||
        (!renderPreview && progressBarNavigationFlag) ||
        progressBarNavigationFlag === true ||
        questionData ? (
          <>
            {isCollapsed2 === true ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                    height: {
                      xs: "300px",
                      sm: "300px",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      height: "100%",
                    }}
                  >
                    <QuestionSideBar question_id={questions[activeQuestion]} />
                  </div>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      {showModal && (
        <Dialog
          open={showModal}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: { borderRadius: "30px" },
          }}
        >
          <Box
            style={{
              padding: "25px",
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button style={{ justifyContent: "end" }}>
              <Close onClick={handleClosePopup} />
            </Button>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  style={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "24px",
                  }}
                >
                  <FormattedMessage id="no-answer-popup-title" />
                </Typography>
                {/* <Typography
                  style={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  <FormattedMessage id="modal-text" />
                </Typography> */}
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="modal-text" />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "block",
                paddingTop: "20px",
                marginInline: "auto",
              }}
            >
              <Stack direction="row" justifyContent="center" width="100%">
                <Button
                  onClick={handleClosePopup}
                  autoFocus
                  variant="contained"
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="no" />
                </Button>
                <Button
                  onClick={nextPage}
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="yes" />
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      {allQuestionAttempt === true ? (
        <>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
                borderRadius: "0px 30px 30px 0px",
                height: "46px",
                width: "52px",
                position: "absolute",
                top: "85px",
                left: "0",
                right: "unset",
                textAlign: "center",
              }}
            >
              <IconButton onClick={handleIconCClick}>
                <EditIconCollaps />
              </IconButton>
              <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                sx={{
                  position: "fixed",
                  top: "100px",
                  left: "0 !important",
                  right: "unset",
                  height: "330px",
                  width: "300px",
                  background: "#FFFFFF",
                  border: "1px solid #DFE2E5",
                  borderRadius: "0 30px 30px 0",
                  overflow: "hidden",
                }}
              >
                <div
                  className="modal-content"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "0 30px 30px 0",
                  }}
                >
                  <IconButton
                    onClick={handleIconCClick}
                    style={{ position: "absolute", left: "10px", top: "10px" }}
                  >
                    <EditIconCollapsModel />
                  </IconButton>
                  <ArrowRight
                    onClick={handleCloseModal}
                    style={{ position: "absolute", right: "10px", top: "15px" }}
                  />

                  <QuestionSideBar question_id={questions[activeQuestion]} />
                </div>
              </Modal>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TheOvenSLQuestionPage;
