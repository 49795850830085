import { useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Box,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchVerifyOtp } from "Redux/feature/UserAuth";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const workerId = useAppSelector((state) => state.UserAuth.workerId);
  const [code, setCode] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const responseMessage: any = useAppSelector(
    (state) => state.UserAuth.message
  );
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    dispatch(
      FetchVerifyOtp({ username: workerId, otp: data.get("code") })
    ).then((e) => {
      if (e.payload.status) {
        if (e.payload.data.is_verified === 1) {
          localStorage.setItem("otp_verification_token",e.payload.data.otp_verified_token)
          navigate("/create-password");
        } else {
          navigate("/");
        }
      } else {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
      }
    });
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setIsNextDisabled(!event.target.value);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={{ xs: "100%", sm: "394px" }}
        sx={{
          mx: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "15px",
          pb: "35px",
          "@media (min-width:900px)": {
            paddingTop: "80px",
          },
        }}
      >
        <Box width="100%">
          <Typography
            sx={{ fontSize: "32px", fontWeight: 700 }}
            className="login-title"
          >
            <FormattedMessage id="user-verification" />
          </Typography>
        </Box>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <Typography
            variant="subtitle1"
            textAlign={{ xs: "center", md: "right" }}
          >
            <FormattedMessage id="leave-message-phone" />
          </Typography>
          <InputLabel
            htmlFor="code"
            sx={{ marginTop: "40px", marginBottom: "5px" }}
          >
            <FormattedMessage id="verification-code" />
          </InputLabel>
          <TextField
            margin="none"
            required
            fullWidth
            id="code"
            name="code"
            type="text"
            size="small"
            sx={{ fontSize: "16px" }}
            value={code}
            onChange={handleCodeChange}
          />
          {showAlert && (
            <Alert
              className="removeIconLessonBooksAlert"
              sx={{
                width: "auto",
                height: "auto",
                color: "#E74C3C",
                fontWeight: 600,
                fontSize: "16px",
                padding: "0px",

                backgroundColor: "transparent",
              }}
            >
              {responseMessage}{" "}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: "40px", mb: 2, height: "40px", fontSize: "18px" }}
            disabled={isNextDisabled}
          >
            <FormattedMessage id="next" />
          </Button>
        </Box>
        <Link
          color="primary"
          href="#"
          variant="body2"
          sx={{ marginTop: "17px", fontSize: "18px" }}
        >
          <FormattedMessage id="send-again" />
        </Link>
      </Box>
    </Box>
  );
};

export default Verification;
