import React from "react";
import { Stepper, Step, StepLabel, styled, Box, Radio } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { StepIconProps } from "@mui/material/StepIcon";

interface CustomMobileStepperProps {
  activeStep: number;
  steps: number;
  sx?: object;
}

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[400],
  ...(ownerState.active && {
    color: "#33CD75",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#33CD75",
    fontSize: "1.2rem",
  },
  "& .QontoStepIcon-circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: `2px solid ${
      ownerState.active ? "#33CD75" : theme.palette.grey[400]
    }`,
    backgroundColor: ownerState.completed ? "#33CD75" : "transparent",
  },
  "& .QontoStepIcon-activeIcon": {
    zIndex: 1,
    marginRight: "-3px",
    padding: "0",
    color: "#33CD75 !important",
    svg: {
      [theme.breakpoints.up("xs")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.up("md")]: {
        width: "1em",
        height: "1em",
      },
    },
  },
}));

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {active ? (
        <Radio checked className="QontoStepIcon-activeIcon" />
      ) : completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

const CustomMobileStepper = (props: CustomMobileStepperProps) => {
  const { activeStep, steps, sx } = props;

  return (
    <Box
      sx={{
        maxWidth: 200,
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        ...sx,
      }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={null}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          "& .MuiStep-root": {
            padding: "0 8px",
          },
        }}
      >
        {[...Array(steps)].map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomMobileStepper;
