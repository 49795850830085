import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  MenuItem,
  Badge,
  Tabs,
  Tab,
  IconButton,
  MenuList,
  Grid,
  Modal,
  Select,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon, Close, Logout } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import kidumLogo from "../../assets/images/kidum-logo.jpg";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  deleteOneNotification,
  FetchNotification,
  FetchDeletedNotification,
  FetchReadNotification,
  readOneNotification,
} from "Redux/feature/NotificationSlice";
import { Flag } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CourseList from "views/Teachers/CourseList";
import { setCurrentTab } from "Redux/feature/StudentHome";

import { getImageUrl } from "hooks/imageUrl";
import { getCookie, removeAllCookies } from "views/common/cookieUtils";
import { clientLogout } from "Redux/feature/UserAuth";

const settings = ["Profile", "Account", "Dashboard", "Logout"];
interface UserRoutes {
  routes: string[];
  pages: string[];
}

interface Routes {
  [key: string]: { route: UserRoutes; value: string; tabindex: number } | any;
}

const ROUTES: Routes = {
  Student: {
    routes: [
      { route: "/student-home", value: "student-home", tabindex: 0 },
      {
        route: "/home/learning-management",
        value: "learning-management",
        tabindex: 1,
      },
      { route: "/student/library", value: "library", tabindex: 2 },
      { route: "/home/daybook", value: "diary", tabindex: 3 },
      { route: "/home/tools/roboclock", value: "tools", tabindex: 4 },
    ],
    pages: ["student-home", "learning-management", "library", "diary", "tools"],
  },
  Teacher: {
    routes: [
      { route: "/teacher-home", value: "teacher-home", tabindex: 0 },
      {
        route: "/home/student-management/class-map",
        value: "student-management",
        tabindex: 1,
      },
      {
        route: "/home/classroom-management",
        value: "classroom-management",
        tabindex: 2,
      },
      { route: "/home/library/product-page", value: "library", tabindex: 3 },
    ],
    pages: [
      { route: "teacher-home", value: "teacher-home" },
      { route: "student-management", value: "student-management" },
      { route: "classroom-management", value: "classroom-management" },
      { route: "library", value: "library" },
    ],
  },
};

const checkLearningManagementGroup = (path: string) => {
  return path.includes("learning-management");
};

const checkStudentManagementGroup = (path: string) => {
  return path.includes("student-management");
};

const checkClassRoomManagementGroup = (path: string) => {
  return path.includes("classroom-management");
};

const Header = () => {
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [routes, setRoutes] = React.useState<any[]>([]);
  const [pages, setPages] = React.useState<string[]>([]);
  const { notification } = useAppSelector((state) => state.UserNotification);
  const profileImage = useAppSelector(
    (state) => state.StudentsHomeData.profileImage
  );
  const user_data = useAppSelector((state) => state.UserNotification.user_data);
  const profileImageTeacher = useAppSelector(
    (state) => state.TeacherSlice?.teacherProfileData?.profile_pic
  );
  const defaultCourseId = getCookie("default_course_id");

  const currentTab = useAppSelector(
    (state) => state.StudentsHomeData.currentTab
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const dispatch = useAppDispatch();
  const currentPath = useCurrentPath();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleDialogueClose();
  };
  const isMediumScreen = useMediaQuery("(min-width: 900px)");

  const isMobile = useMediaQuery("(max-width:767px)");

  const handleDialogueOpen = () => setOpenDialogue(true);
  const handleDialogueClose = () => setOpenDialogue(false);

  const user_id = getCookie("id");

  const storageUserType = getCookie("user_type");

  useEffect(() => {
    if (user_id) {
      dispatch(FetchNotification({ user_id: user_id }));
    }
    handleClose();
  }, [user_id]);

  React.useEffect(() => {
    if (storageUserType) {
      setRoutes(ROUTES[storageUserType].routes);
      setPages(ROUTES[storageUserType].pages);
    }
    const tabIndex: number | undefined = routes?.find(
      (route: any) => route.route === currentPath
    )?.tabindex;
    if (routes.includes(currentPath)) {
      dispatch(setCurrentTab(routes.indexOf(currentPath)));
    }
    if (tabIndex !== undefined) {
      dispatch(setCurrentTab(tabIndex));
    }
  }, [routes, currentPath, storageUserType]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleRead = (id: number) => {
    dispatch(
      FetchReadNotification({
        notification_id: id,
        is_read: 1,
      })
    ).then((resp) => {
      if (resp.payload.code === 200) {
        if (user_id) {
          dispatch(readOneNotification(id));
        }
      }
    });
  };
  const handleDelete = (id: number) => {
    dispatch(
      FetchDeletedNotification({
        notification_id: id,
        is_remove: 1,
      })
    ).then((resp) => {
      if (resp.payload.code === 200) {
        if (user_id) {
          dispatch(deleteOneNotification(id));
        }
      }
    });
  };
  const navigationTab = (page: any) => {
    dispatch(setCurrentTab(page.tabindex));
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    checkStudentManagementGroup(routes[newValue]) ||
    checkLearningManagementGroup(routes[newValue]) ||
    checkClassRoomManagementGroup(routes[newValue])
      ? dispatch(setCurrentTab(1))
      : dispatch(setCurrentTab(newValue));
    navigate(routes[newValue], { replace: true });
  };

  const bagIcons = notification?.filter((obj) => obj?.is_read === 0);
  const scoreModalView = {
    position: "absolute" as "absolute",
    top: { xs: "54%", md: "58%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "325px",
    padding: "15px",
    maxWidth: "calc(100% - 30px)",
    background: "#F8F9FA",
    border: "1px solid #D0D4D9",
    boxShadow: 24,
    overflow: "auto",
    paddingLeft: 0,
    borderRadius: "20px",
    xs: {
      borderRadius: "8px",
    },
  };

  const handleLogout = (event: any) => {
    dispatch(clientLogout({ user_id: String(user_id),course_id: defaultCourseId ? defaultCourseId : selectedCourse})).then((resp: any) => {
      if (resp) {
        localStorage.clear();
        removeAllCookies();

        const keys = Object.keys(localStorage);

        if (keys.length === 0) {
          window.location.reload();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      }
    });
  };

  const profile = () => {
    if (storageUserType === "Student") {
      navigate(`/home/edit-student-profile/${user_id}`);
      dispatch(setCurrentTab(null));
    } else {
      navigate("/home/teacher-profile");
    }
  };

  const [openSelect, setOpenSelect] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const handleSelectClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      setOpenSelect(!openSelect);
    }
  };

  // useEffect(() => {
  //   if (storageUserType === "Student") {
  //     dispatch(
  //       FetchOnboardingInfo({
  //         student_id: user_id,
  //       })
  //     ).then((res: any) => {
  //       if (res?.payload?.status === true) {
  //         dispatch(setProfileImage(res?.payload?.user_info?.profile_picture));
  //       }
  //     });
  //   }
  // }, [user_id, storageUserType]);

  const handleSelectClose = () => {
    setOpenSelect(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    setOpenSelect(false);
  };
  const handleImageClick = () => {
    if (storageUserType === "Student") {
      navigate("/student-home");
      dispatch(setCurrentTab(0));
    } else if (storageUserType === "Teacher") {
      navigate("/teacher-home");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: "0",
          left: "0",
          width: "100%",
          backgroundColor: "white",
          color: "grey",
          borderBottom: "1px solid #D0D4D9;",
          boxShadow: "none",
          zIndex: "99",
          height: "fit-content",
        }}
      >
        <Container>
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              alignItems: { xs: "center", md: "end" },
              pt: { xs: "4px", md: "0" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton
                className="student-home-mobile-1"
                sx={{
                  display: { padding: "11px", xs: "block", md: "none" },
                }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appBar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <img
                className="header-logo"
                src={kidumLogo}
                alt="kidum"
                loading="lazy"
                title="kidum"
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
              />
              <Box
                className="student-home-1"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                {!isMobile && <CourseList />}{" "}
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                padding: "11px",
                display: { xs: "flex", md: "none" },
              }}
            >
              <Menu
                id="menu-appBar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  padding: "11px",
                  pointerEvents: anchorElNav ? "auto" : "none",
                  display: { xs: "block", md: "none" },
                  color: "#000",
                }}
              >
                {isMobile && <CourseList />}

                {routes.map((page: any, index: number) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Link
                      to={page.route}
                      style={
                        location.pathname === page.route
                          ? {
                              color: "#FB0035",
                              textDecoration: "none",
                              fontWeight: "700",
                            }
                          : { color: "#878E95", textDecoration: "none" }
                      }
                    >
                      <FormattedMessage id={page.value} />
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              className="student-home-2"
              sx={{
                height: "100%",
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
                position: "relative",
                right: storageUserType === "Teacher" ? "0" : "-30px",
              }}
            >
              <Tabs
                sx={{
                  height: "100%",
                  "& .MuiTabs-indicator": {
                    backgroundColor: theme.palette.error.main,
                  },
                }}
                value={currentTab}
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                {routes.map((page: any, index: number) => (
                  <Link
                    key={index}
                    className="main-menu-link"
                    to={page.route}
                    style={{ padding: "0 !important" }}
                  >
                    <Tab
                      onClick={() => {
                        navigationTab(page);
                        localStorage.setItem("tabNumber", "0");
                      }}
                      key={page}
                      style={
                        currentTab === page.tabindex
                          ? {
                              color: "#FB0035",
                              textDecoration: "none",
                              opacity: "1",
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "18px",
                              minWidth: "1px",
                              paddingLeft: "0 !important",
                              paddingRight: "0 !important",
                            }
                          : {
                              color: "#878E95",
                              textDecoration: "none",
                              opacity: "1",
                              fontSize: "18px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              minWidth: "1px",
                              paddingLeft: "0 !important",
                              paddingRight: "0 !important",
                            }
                      }
                      color="error"
                      label={<FormattedMessage id={page.value} />}
                      sx={{
                        "&.MuiTab-root.Mui-selected": {
                          color: theme.palette.error.main,
                        },
                      }}
                    />
                  </Link>
                ))}
              </Tabs>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Box className="student-home-mobile-2">
                <Badge
                  id="basic-button"
                  sx={{
                    padding: "5px",
                    "&:hover": {
                      backgroundColor: "#F1F3F5",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                  color="error"
                  onClick={isMediumScreen ? handleClick : handleDialogueOpen}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                >
                  {bagIcons?.length > 0 && (
                    <Box
                      sx={{
                        backgroundColor: "#FB0035",
                        width: "17px",
                        height: "17px",
                        borderRadius: "100%",
                        fontSize: "10px",
                        fontWeight: "600",
                        color: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: 16,
                        border: "2px solid #fff",
                        top: 2,
                      }}
                    >
                      {bagIcons?.length}
                    </Box>
                  )}
                  <svg
                    width="25"
                    height="28"
                    viewBox="0 0 25 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 19 3 21 3 21H21C21 21 18 19 18 12Z"
                      stroke="#4A5056"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.73 25C13.5542 25.3031 13.3019 25.5547 12.9982 25.7295C12.6946 25.9044 12.3504 25.9965 12 25.9965C11.6496 25.9965 11.3054 25.9044 11.0018 25.7295C10.6982 25.5547 10.4458 25.3031 10.27 25"
                      stroke="#4A5056"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Badge>
                <Menu
                  className="notification-class"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    borderRadius: "8px",
                    outline: "none",
                    p: "0",
                    maxHeight: "80vh",
                    overflow: "auto",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuList
                    sx={{
                      backgroundColor: "#ffff",
                      border: "1px solid #DFE2E5",
                      boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.05)",
                      borderRadius: "8px",
                      outline: "none",
                      p: "0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        fontSize: "16px",
                        pb: "10px",
                        px: "1em",
                        paddingTop: "1em",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormattedMessage id="messages" />
                      <Link
                        to={
                          storageUserType === "Teacher"
                            ? "/notifications-teacher"
                            : "/notifications"
                        }
                        onClick={handleClose}
                      >
                        <FormattedMessage id="all-messages" />
                      </Link>
                    </Box>
                    <MenuItem
                      sx={{
                        overflowWrap: "break-word",
                        p: "0",
                        maxWidth: "310px",
                        fontWeight: "bold",
                      }}
                    >
                      <Box sx={{ fontSize: "1.4em", p: "0", width: "320px" }}>
                        <>
                          {notification?.map(
                            (item: any, index: number) =>
                              item.is_deleted !== 1 && (
                                <>
                                  {item.is_read === 1 ? (
                                    <MenuItem
                                      key={index}
                                      style={{ flexWrap: "wrap" }}
                                      sx={{
                                        py: "8px",
                                        backgroundColor: "#fff",
                                        borderBottom: "1px solid #DFE2E5",
                                        "& .close-icon": {
                                          opacity: "0",
                                          display: "block",
                                          transition: "opacity 0.2s",
                                        },
                                        ":hover": {
                                          backgroundColor: "#F7F4FB",
                                          "& .close-icon": {
                                            opacity: "1",
                                          },
                                        },
                                      }}
                                      onClick={() => handleRead(item.id)}
                                    >
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          width: "100%",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <Grid>
                                          <FormattedMessage id="title" />
                                        </Grid>
                                        <Grid style={{ display: "flex" }}>
                                          <Close
                                            sx={{
                                              opacity: "1",
                                            }}
                                            className="close-icon"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                          />
                                          {item?.is_important === 1 ? (
                                            <Flag color="error" />
                                          ) : null}
                                        </Grid>
                                      </Grid>
                                      {item.notification_text && (
                                        <Typography
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "300px",
                                          }}
                                          sx={{
                                            whiteSpace: "pre-line",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            width: "100%",
                                          }}
                                        >
                                          {item.notification_text}
                                        </Typography>
                                      )}
                                      {item.created_time && item.created && (
                                        <Typography
                                          style={{
                                            fontSize: "14px",
                                            color: "#878E95",
                                          }}
                                        >
                                          {item.created_time} | {item.created}
                                        </Typography>
                                      )}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      style={{ flexWrap: "wrap" }}
                                      sx={{
                                        py: "8px",
                                        backgroundColor: "#F7F4FB",
                                        borderBottom: "1px solid #DFE2E5",
                                        "& .close-icon": {
                                          opacity: "0",
                                          display: "block",
                                          transition: "opacity 0.2s",
                                        },
                                        ":hover": {
                                          backgroundColor: "#fff",
                                          "& .close-icon": {
                                            opacity: "1",
                                          },
                                        },
                                      }}
                                      onClick={() => handleRead(item.id)}
                                    >
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          width: "100%",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <Grid>
                                          <FormattedMessage id="title" />
                                        </Grid>
                                        <Grid style={{ display: "flex" }}>
                                          <Close
                                            sx={{
                                              display: "none",
                                            }}
                                            className="close-icon"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                          />
                                          {item?.is_important === 1 ? (
                                            <Flag color="error" />
                                          ) : null}
                                        </Grid>
                                      </Grid>
                                      <Typography
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          maxWidth: "300px",
                                        }}
                                        sx={{
                                          whiteSpace: "pre-line",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          width: "100%",
                                        }}
                                      >
                                        {item.notification_text}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: "14px",
                                          color: "#878E95",
                                        }}
                                      >
                                        {item.created} | {item.created_time}
                                      </Typography>
                                    </MenuItem>
                                  )}
                                </>
                              )
                          )}
                        </>
                      </Box>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Link
                to={
                  storageUserType === "Teacher"
                    ? "/notifications-teacher"
                    : "/notifications"
                }
                color="black"
                style={{ display: "none" }}
              >
                <Button
                  color="neutral"
                  sx={{
                    direction: "ltr",
                    mr: "0",
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  endIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M22 12H16L14 15H10L8 12H2"
                        stroke="#4A5056"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11V5.11Z"
                        stroke="#4A5056"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                >
                  <FormattedMessage id="messages" />
                  {notification?.length}
                </Button>
              </Link>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleSelectClick}
              >
                <Avatar
                  alt="aemy Sharp"
                  src={getImageUrl(
                    storageUserType === "Student"
                      ? user_data?.profile_pic
                        ? user_data?.profile_pic
                        : ""
                      : profileImageTeacher
                      ? profileImageTeacher
                      : "",
                    ""
                  )}
                  sx={{
                    width: 26,
                    height: 26,
                    ml: { xs: "0", sm: "7px" },
                    mr: "10px",
                  }}
                />
                <InputLabel
                  sx={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    display: { xs: "none", md: "flex" },
                  }}
                  htmlFor="select-placeholder"
                >
                  {/* היי  */}
                  {/* <FormattedMessage id="hi" /> */}
                  {` ${getCookie("user_name")}`}
                </InputLabel>
                <Select
                  labelId="select-placeholder"
                  open={openSelect}
                  onClose={handleSelectClose}
                  onOpen={() => {}}
                  ref={selectRef}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  className="profile-block"
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    color: "#E74C3C",
                    fontSize: "12px",
                    border: "none",
                    p: 0,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiSvgIcon-root.MuiSelect-icon": {},
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    style: {},
                  }}
                >
                  <MenuItem key="1" sx={{ p: 0 }}>
                    <Button
                      sx={{
                        justifyContent: "flex-start",
                        p: "10px 15px",
                        color: "#4A5056",
                        width: "100%",
                        textAlign: "left",
                      }}
                      startIcon={
                        <Avatar
                          alt="aemy Sharp"
                          src={getImageUrl(
                            storageUserType === "Student"
                              ? profileImage
                                ? profileImage
                                : ""
                              : profileImageTeacher
                              ? profileImageTeacher
                              : "",
                            ""
                          )}
                          sx={{
                            width: 26,
                            height: 26,
                            ml: 2,
                          }}
                        />
                      }
                      onClick={profile}
                    >
                      <FormattedMessage id="userProfile" />
                    </Button>
                  </MenuItem>
                  <MenuItem key="2" value="test" sx={{ p: 0 }}>
                    <Button
                      sx={{
                        justifyContent: "flex-start",
                        p: "10px 15px",
                        color: "#4A5056",
                        width: "100%",
                        textAlign: "left",
                      }}
                      startIcon={<Logout sx={{ ml: 2 }} />}
                      onClick={handleLogout}
                    >
                      <FormattedMessage id="logout" />
                    </Button>
                  </MenuItem>
                </Select>
              </Box>
              <Menu
                sx={{
                  backgroundColor: "rgba(135, 142, 149, 0.5)",
                  zIndex: "0",
                  top: "0",
                  visibility: "visible",
                  marginTop: "0",
                  padding: "11px",
                  opacity: "1",
                  transition: "all 0.2s",
                }}
                className="main-menu"
                id="menu-appBar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting: any, index: number) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <div>
        <Modal
          open={openDialogue}
          onClose={handleDialogueClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box sx={scoreModalView}>
              <MenuList
                sx={{
                  maxHeight: { xs: "75vh", md: "80vh" },
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "16px",
                    py: "1em",
                    px: "1em",
                    paddingTop: "1em",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#222529",
                    }}
                  >
                    {" "}
                    <FormattedMessage id="messages" />
                  </span>
                  <Link
                    to={
                      storageUserType === "Teacher"
                        ? "/notifications-teacher"
                        : "/notifications"
                    }
                    onClick={handleDialogueClose}
                    style={{ color: "#7A49B9", fontWeight: 400 }}
                  >
                    <FormattedMessage id="all-messages" />
                  </Link>
                </Box>
                <MenuItem
                  sx={{
                    overflowWrap: "break-word",
                    p: "0",
                    maxWidth: "310px",
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ fontSize: "1.4em", width: "320px" }}>
                    <>
                      {notification?.map(
                        (item: any, index: number) =>
                          item.is_deleted !== 1 && (
                            <>
                              {item.is_read === 1 ? (
                                <MenuItem
                                  key={index}
                                  style={{
                                    flexWrap: "wrap",
                                    minHeight: "118px",
                                  }}
                                  sx={{
                                    marginTop: "8px",
                                    backgroundColor: "#F7F4FB",
                                    borderRadius: "10px",
                                    border: "2px solid  #7A49B9",
                                    "& .close-icon": {
                                      opacity: "0",
                                      display: "block",
                                      transition: "opacity 0.2s",
                                    },
                                    ":hover": {
                                      backgroundColor: "#fff",
                                      "& .close-icon": {
                                        opacity: "1",
                                      },
                                    },
                                  }}
                                  onClick={() => handleRead(item.id)}
                                >
                                  <Grid
                                    container
                                    style={{
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <Grid>
                                      <FormattedMessage id="title" />
                                    </Grid>
                                    <Grid style={{ display: "flex" }}>
                                      <Close
                                        sx={{
                                          opacity: "1",
                                        }}
                                        className="close-icon"
                                        onClick={() => handleDelete(item.id)}
                                      />
                                      {item?.is_important === 1 ? (
                                        <Flag color="error" />
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                  <Typography
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "300px",
                                    }}
                                    sx={{
                                      whiteSpace: "pre-line",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      width: "100%",
                                    }}
                                  >
                                    {item.notification_text}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      color: "#878E95",
                                    }}
                                  >
                                    {item.created === null &&
                                    item.created_time === null
                                      ? ""
                                      : item.created + "|" + item.created_time}
                                  </Typography>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  style={{
                                    flexWrap: "wrap",
                                    minHeight: "118px",
                                  }}
                                  sx={{
                                    mt: "10px",
                                    py: "8px",
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    border: "2px solid #DFE2E5",
                                    "& .close-icon": {
                                      opacity: "0",
                                      display: "block",
                                      transition: "opacity 0.2s",
                                    },
                                    ":hover": {
                                      backgroundColor: "#F7F4FB",
                                      border: "2px solid #7A49B9",

                                      "& .close-icon": {
                                        opacity: "1",
                                      },
                                    },
                                  }}
                                  onClick={() => handleRead(item.id)}
                                >
                                  <Grid
                                    container
                                    style={{
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <Grid
                                      sx={{ fontWeight: 700, fontSize: "16px" }}
                                    >
                                      <FormattedMessage id="title" />
                                    </Grid>
                                    <Grid style={{ display: "flex" }}>
                                      <Close
                                        sx={{
                                          display: "none",
                                        }}
                                        className="close-icon"
                                        onClick={() => handleDelete(item.id)}
                                      />
                                      {item?.is_important === 1 ? (
                                        <Flag color="error" />
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                  <Typography
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "300px",
                                    }}
                                    sx={{
                                      whiteSpace: "pre-line",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      width: "100%",
                                    }}
                                  >
                                    {item.notification_text}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      color: "#878E95",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.created === null &&
                                    item.created_time === null
                                      ? ""
                                      : item.created + "|" + item.created_time}
                                  </Typography>
                                </MenuItem>
                              )}
                            </>
                          )
                      )}
                    </>
                  </Box>
                </MenuItem>
              </MenuList>
              <Box
                display={{ xs: "flex", md: "none" }}
                sx={{
                  backgroundColor: "#7A49B9",
                  width: "90px",
                  height: "40px",
                  fontSize: "18px",
                  borderRadius: "8px",
                  color: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px",
                  marginRight: "110px",
                  fontWeight: 500,
                  lineHeight: "21.6px",
                }}
                onClick={handleDialogueClose}
              >
                <FormattedMessage id="closer" />
              </Box>
            </Box>
          </>
        </Modal>
      </div>
    </>
  );
};
export default Header;
