import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface fetchAtomicAndContainerData {
  id: string | null;
  course_id: string | number | null;
  teacher_id?: string | null;
}
interface closeSuccessPage {
  student_id: string | null;
  model: string;
  is_close: number;
}
interface completeContainerAtomic {
  student_id: string | null;
  assignment_id: string | null;
  meeting_id: string;
  course_id: string;
  type: string;
  id: string | null;
}

export const FetchAtomicAndContainerData = createAsyncThunk(
  "Students/studentHome",
  async (data: fetchAtomicAndContainerData) => {
    try {
      if (data?.teacher_id) {
        const res = await axiosClient.get(
          `get-atomic-container?student_id=${data.id}&course_id=${data.course_id}&teacher_id=${data.teacher_id}`
        );
        return res.data;
      } else {
        const res = await axiosClient.get(
          `get-atomic-container?student_id=${data.id}&course_id=${data.course_id}`
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetNextAssignment = createAsyncThunk(
  "Students/getNextAssignment",
  async (data: {
    course_id: string;
    meeting_id: string;
    assignment_id: string | null;
    student_id: string | null;
  }) => {
    try {
      const res = await axiosClient.get(
        `get-next-assignment?course_id=${data.course_id}&meeting_id=${data.meeting_id}&assignment_id=${data.assignment_id}&student_id=${data.student_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const FetchAtomic = createAsyncThunk(
  "Students/fetchAtomic",
  async (data: {
    atomic_unit_id: string | null;
    assignment_id: string | null;
  }) => {
    try {
      const res = await axiosClient.get(
        `get-atomic-unit-data?atomic_unit_id=${data.atomic_unit_id}&assignment_id=${data.assignment_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const CompleteContainerAtomic = createAsyncThunk(
  "Students/complete-atomic-container",
  async (data: completeContainerAtomic) => {
    try {
      const res = await axiosClient.post(`complete-atomic-container`, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchDashboardStudentData = createAsyncThunk(
  "Students/studentDashboardData",
  async (data: { student_id: string | null; course_id: string }) => {
    try {
      const res = await axiosClient.get(
        `get-dashboard-data?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const UpdateProfilePicture = createAsyncThunk(
  "BoardingSlice/SaveStudentNotes",

  async (data: any) => {
    try {
      const res = await axiosClient.post("update-profile", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const ActiveCourseForStudent = createAsyncThunk(
  "Students/getActiveCourse",
  async (data: { id: string | undefined }) => {
    try {
      const res = await axiosClient?.get(
        `get-active-courses?student_id=${data?.id}`
      );
      return res?.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetSuccessPage = createAsyncThunk(
  "Student/getSuccessPage",
  async (data: { id: string | null }) => {
    try {
      const res = await axiosClient?.get(
        `get-success-page-flag?student_id=${data?.id}`
      );
      return res?.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const CloseSuccessPage = createAsyncThunk(
  "Student/closeSuccessPage",
  async (data: closeSuccessPage) => {
    try {
      const res = await axiosClient.post(`close-success-page_modal`, data);
      return res?.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const ResetStudentHome = createAction("Student/Reset");
interface InitialState {
  atomicUnits: any[];
  singleAtomic: any;
  isLoading: boolean;
  containers: any[];
  atomic: any[];
  container: any[];
  links: any[];
  completedAtomics: any[];
  completedContainers: any[];
  sideBarData: any[];
  isAtomicGet: boolean;
  completeContainerAtomicIdle: boolean;
  activeAtomicIndex: number;
  atomicUnitsData: any[];
  productType: string;
  isNewUser: number;
  sidebar: any[];
  assignment: any[];
  assignmentIndex: number;
  studentDashboardData: any[];
  activeCourseStudent: any[];
  previousCourseStudent: any[];
  getSuccessPage: any[];
  closeSuccessPage: any[];
  openAssignmentSuccess: boolean;
  openSessionSuccess: boolean;
  profileImage: string;
  iconSidebar: string;
  currentTab: number;
  streak: {
    of_day: number;
    streak: number;
  };
  weekly_assignment: {
    categories: string[][];
    data: number[];
  };
  sessionIcons: {
    name: string;
    icon1: string | null;
    icon2: string | null;
  };
}

const initialState: InitialState = {
  atomicUnits: [],
  singleAtomic: [],
  isLoading: true,
  containers: [],
  isAtomicGet: true,
  container: [],
  links: [],
  completedAtomics: [],
  completedContainers: [],
  completeContainerAtomicIdle: false,
  activeAtomicIndex: 0,
  atomicUnitsData: [],
  productType: "",
  isNewUser: 0,
  sidebar: [],
  atomic: [],
  assignment: [],
  sideBarData: [],
  assignmentIndex: 0,
  studentDashboardData: [],
  activeCourseStudent: [],
  previousCourseStudent: [],
  getSuccessPage: [],
  closeSuccessPage: [],
  openAssignmentSuccess: false,
  openSessionSuccess: false,
  profileImage: "",
  iconSidebar: "",
  currentTab: 0,
  streak: {
    of_day: 0,
    streak: 0,
  },
  weekly_assignment: {
    categories: [["א"], ["ב"], ["ג"], ["ד"], ["ה"], ["ו"]],
    data: [],
  },
  sessionIcons: {
    name: "",
    icon1: null,
    icon2: null,
  },
};

const StudentsHomeData = createSlice({
  name: "student-home-data",
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      return { ...state, activeAtomicIndex: action.payload };
    },
    setCurrentTab: (state, action) => {
      return { ...state, currentTab: action.payload };
    },
    setIconSidebar: (state, action) => {
      return { ...state, iconSidebar: action.payload };
    },
    setProfileImage: (state, action) => {
      return { ...state, profileImage: action.payload };
    },
    setOpenAssignmentSuccess: (state, action) => {
      return { ...state, openAssignmentSuccess: action.payload };
    },
    setOpenSessionSuccess: (state, action) => {
      return { ...state, openSessionSuccess: action.payload };
    },
    setSideBarData: (state, action) => {
      return { ...state, sidebarData: action.payload };
    },
    setAtomic: (state, action) => {
      return { ...state, atomic: action.payload };
    },
    setContainer: (state, action) => {
      return { ...state, container: action.payload };
    },
    setAssignmentData: (state, action) => {
      return { ...state, assignment: action.payload };
    },
    setIndex: (state, action) => {
      return { ...state, assignmentIndex: action.payload };
    },
    setSideBar: (state, action) => {
      return { ...state, sidebar: action.payload };
    },
    setDynamicSessionIcon: (state, action) => {
      const { name, icon1, icon2 } = action.payload;
      return {
        ...state,
        sessionIcons: {
          name,
          icon1,
          icon2,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchAtomicAndContainerData.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          isAtomicGet: true,
        };
      })
      .addCase(FetchAtomicAndContainerData.fulfilled, (state, action) => {
        return {
          ...state,
          atomicUnitsData: action?.payload?.data,
          productType: action?.payload?.product_type,
          isNewUser: action?.payload?.is_new_user,
          atomicUnits:
            action?.payload?.data[0]?.meeting?.assignment[0]?.atomicUnits,
          containers:
            action.payload?.data[0]?.meeting?.assignment[0]?.containers,
          links: action?.payload?.data?.links,
          completedAtomics: action?.payload?.completedAtomics,
          completedContainers: action?.payload?.completedContainers,
          isLoading: false,
          isAtomicGet: false,
          streak: action?.payload?.streak,
        };
      })
      .addCase(FetchAtomicAndContainerData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: true,
          isAtomicGet: true,
        };
      })
      .addCase(FetchAtomic.pending, (state, action) => {
        return {
          ...state,
          isLoading: false,
          singleAtomic: [],
          isAtomicGet: true,
        };
      })
      .addCase(FetchAtomic.fulfilled, (state, action) => {
        return {
          ...state,
          singleAtomic: action.payload.data,
          isLoading: false,
          isAtomicGet: false,
        };
      })
      .addCase(FetchAtomic.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          singleAtomic: [],
          isLoading: true,
          isAtomicGet: false,
        };
      })
      .addCase(GetNextAssignment.pending, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isAtomicGet: true,
        };
      })
      .addCase(GetNextAssignment.fulfilled, (state, action) => {
        return {
          ...state,
          assignmentDetail: action.payload.data,
          assignment_completed: action.payload.assignment_completed,
          meeting_completed: action.payload.meeting_completed,
          isLoading: false,
          isAtomicGet: false,
        };
      })
      .addCase(GetNextAssignment.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: true,
          isAtomicGet: false,
        };
      })
      .addCase(CompleteContainerAtomic.pending, (state, action) => {
        return {
          ...state,
          completeContainerAtomicIdle: false,
        };
      })
      .addCase(CompleteContainerAtomic.fulfilled, (state, action) => {
        return {
          ...state,
          completeContainerAtomicIdle: false,
        };
      })
      .addCase(CompleteContainerAtomic.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          completeContainerAtomicIdle: true,
        };
      })
      .addCase(FetchDashboardStudentData.pending, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(FetchDashboardStudentData.fulfilled, (state, action) => {
        return {
          ...state,
          studentDashboardData: action.payload.data,
          weekly_assignment: action.payload?.data?.weekly_assignment,

          isLoading: false,
        };
      })
      .addCase(FetchDashboardStudentData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: true,
        };
      })
      .addCase(UpdateProfilePicture.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: true,
        };
      })
      .addCase(UpdateProfilePicture.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(UpdateProfilePicture.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ActiveCourseForStudent.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(ActiveCourseForStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          activeCourseStudent: action?.payload?.activecourses,
          previousCourseStudent: action?.payload?.previouscourselist,
        };
      })
      .addCase(ActiveCourseForStudent.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetSuccessPage.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetSuccessPage.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getSuccessPage: action?.payload?.data,
        };
      })
      .addCase(GetSuccessPage.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(CloseSuccessPage.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(CloseSuccessPage.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          closeSuccessPage: action?.payload?.data,
        };
      })
      .addCase(CloseSuccessPage.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ResetStudentHome, (state, action) => {
        return {
          ...state,
          atomicUnitsData: action?.payload ?? [],
          // ...initialState,
          // openAssignmentSuccess: state.openAssignmentSuccess,
          // openSessionSuccess: state.openSessionSuccess,
        };
      });
  },
});

export const {
  setActiveIndex,
  setSideBarData,
  setAtomic,
  setContainer,
  setAssignmentData,
  setIndex,
  setSideBar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setProfileImage,
  setCurrentTab,
  setIconSidebar,
  setDynamicSessionIcon,
} = StudentsHomeData.actions;
export default StudentsHomeData.reducer;
